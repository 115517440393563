<script lang="ts">
  import Checkbox from "@ui/Checkbox.svelte";
  import { Footer } from "./shared";

  export let owner: { name: string; email: string };
  export let organizationName: string;
  export let loginUrl: string;
  export let errorCode: string | undefined = undefined;
  export let email: string;

  let checked = false;
</script>

<div class="flex flex-col justify-between min-h-screen">
  <div class="login-page connect-with-saml">
    <div class="header">
      <div class="w-[1000px] mr-auto ml-auto">
        <a href="/">
          <div class="smore-logo"></div>
        </a>
      </div>
    </div>
    <div class="form-container login">
      <div class="top">
        <h3 class="title">Welcome to {organizationName} SAML login!</h3>
        <div class="clear"></div>
      </div>

      <div class="main-content">
        <form class="signup-form" action={loginUrl} method="post">
          {#if errorCode}
            <div class="error">
              {#if errorCode === "full"}
                <p>
                  Your organization has a Smore for Teams account, but it has exceeded its user limit. Please contact the license
                  administrator, {owner.name} at
                  <a href="mailto:${owner.email}" class="underline link">{owner.email}</a>.
                </p>
              {:else if errorCode}
                <p>
                  {errorCode}
                </p>
              {:else}
                <p>It seems that something went wrong.</p>

                <p>
                  Please try logging in again or <a href="mailto:support_email_address" class="underline link">email us</a>.
                </p>
              {/if}
            </div>
          {/if}

          <div class="message">
            <div>Please use your login credentials to access Smore.</div>
          </div>

          <div class="clear"></div>
          <label class="flex items-start mt-6">
            <Checkbox name="terms" id="terms" bind:checked size="small" />
            <p class="ml-2 -mt-1 text-nxgray-300 text-12">
              By signing up for a Smore Account, you agree to our <a href="https://www.smore.com/terms/" class="link hover:underline"
                >Terms of Service</a>
              and
              <a href="https://www.schoolstatus.com/master-services-agreement" class="link hover:underline"> Master Services Agreement</a>
              unless your organization has agreed to other terms in writing.
            </p>
          </label>
          <button
            class="login-button"
            disabled={!checked}
            class:bg-[#ef7b4a]={checked}
            class:hover:bg-orange-600={checked}
            class:text-white={checked}
            class:bg-nxgray-200={!checked}
            class:text-nxgray-50={!checked}
            class:clickable={checked}
            class:cursor-default={!checked}>Continue</button>
        </form>
        <br />
        <div class="mistake"><a href="/" class="text-[#1d9eeb]">&lsaquo; Go back</a></div>
      </div>
    </div>
  </div>
  <Footer />
</div>

<style>
  .connect-with-saml .message {
    color: #505060;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .connect-with-saml .mistake {
    text-align: center;
    font-size: 13px;
  }

  .login-page {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .login-page .header {
    min-height: 263px;
    background: #dcf4fc url("https://cdn.smore.com/images/header-bg.f34c00be.png") no-repeat bottom center;
    box-sizing: border-box;

    padding-top: 40px;
    padding-left: 40px;
  }

  .login-page .header .smore-logo {
    background: url("https://cdn.smore.com/images/logo.ce8c5cf3.png");
    height: 38px;
    width: 137px;
  }

  .login-page .form-container {
    width: 500px;
    min-height: 200px;
    background: #f7f9fa;
    margin: -90px auto 0 auto;
    border: 1px #c9d3da solid;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  }

  .login-page .form-container .top {
    background: #ffffff;
    padding: 22px 30px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px #c9d3da solid;
  }

  .login-page .form-container .top .title {
    font-size: 18px;
    font-weight: 600;
    color: #485a69;
    background: url("https://cdn.smore.com/images/lock-icon.45f7972a.png") no-repeat;
    padding-left: 30px;
    display: inline-block;
  }

  .login-page .form-container .main-content {
    padding: 26px 30px;
  }

  .login-page .form-container .main-content .error {
    background: #de5242;
    padding: 6px 12px 8px 12px;
    border-radius: 3px;
    margin-bottom: 15px;
  }

  .login-page .form-container .main-content .login-button {
    border: 0 solid transparent;
    border-radius: 5px;

    padding: 6px 12px 8px 12px;

    box-shadow:
      0 3px 0 rgba(0, 0, 0, 0.1),
      inset 0 -3px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    margin-top: 10px;
    display: block;

    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.22);
    text-align: center;
    color: #fff;
  }
</style>
